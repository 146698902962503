import { createAuth0 } from "@auth0/auth0-vue";

export const auth0 = createAuth0({
	domain: process.env.VUE_APP_AUTH0_DOMAIN, // https://nationalautocare-dev.us.auth0.com
	clientId: process.env.VUE_APP_CLIENT_ID,
	cacheLocation: "localstorage", // provides persistence across page refreshes and browser tabs
	useCookiesForTransactions: true, // adds ability to authenticate using flows that may end up spanning across multiple tabs
	authorizationParams: {
		audience: process.env.VUE_APP_AUDIENCE, // urn:uat-nac-api
		redirect_uri: process.env.VUE_APP_REDIRECT_URI, // http://.../auth-callback
		scope: process.env.VUE_APP_SCOPE, // openid profile email offline_access
		// WHEN USING PRG's startSSO URL connection CANNOT BE AN EMPTY STRING
		connection: process.env.VUE_APP_CONNECTION, // enterprise connection name
	},
});
