import Vuex from "vuex";
import { number } from "yup";

// Create Vuex data store
export const store = new Vuex.Store({
	state: {
		currentUserDetail: undefined,
		loader: false,
		ContractUrl: "",
		ClaimUrl: "currently not used",
		NewCustomerValid: false,
		SourceTypeCompleted: false,
		ReportedByCompleted: false,
		CustomerHasCoverage: false,
		sourceType: number,
		ContractNumber: "",
		ClaimNumber: "",
		ClaimResults: "Please select a VIN first...",
	},
	getters: {
		getCurrentUserDetail: (state) => {
			return state.currentUserDetail;
		},
		getLoader: (state) => {
			return state.loader;
		},
		getContractUrl: (state) => {
			return state.ContractUrl;
		},
		getClaimUrl: (state) => {
			return state.ClaimUrl;
		},
		getNewCustomerValid: (state) => {
			return state.NewCustomerValid;
		},
		getSourceTypeCompleted(state) {
			return state.SourceTypeCompleted;
		},
		getReportedByCompleted(state) {
			return state.ReportedByCompleted;
		},
		getCustomerHasCoverage(state) {
			return state.CustomerHasCoverage;
		},
		getSourceType: (state) => {
			return state.sourceType;
		},
		getContractNumber: (state) => {
			return state.ContractNumber;
		},
		getClaimNumber: (state) => {
			return state.ClaimNumber;
		},
		getClaimResults: (state) => {
			return state.ClaimResults;
		},
	},
	mutations: {
		CURRENT_USER_DETAIL(state, userDetail) {
			state.currentUserDetail = userDetail;
		},
		LOADER(state, value) {
			state.loader = value;
		},
		setContractUrl(state, value) {
			state.ContractUrl = value;
		},
		setClaimUrl(state, value) {
			state.ClaimUrl = value;
		},
		setNewCustomerValid(state, value) {
			state.NewCustomerValid = value;
		},
		setSourceTypeCompleted(state, value) {
			state.SourceTypeCompleted = value;
		},
		setReportedByCompleted(state, value) {
			state.ReportedByCompleted = value;
		},
		setCustomerHasCoverage(state, value) {
			state.CustomerHasCoverage = value;
		},
		setSourceType(state, value) {
			state.sourceType = value;
		},
		setContractNumber(state, value) {
			state.ContractNumber = value;
		},
		setClaimNumber(state, value) {
			state.ClaimNumber = value;
		},
		setClaimResults(state, value) {
			state.ClaimResults = value;
		},
	},
	actions: {
		["setCurrentUser"]({ commit }, userDetail) {
			commit("CURRENT_USER_DETAIL", userDetail);
		},
		["showLoader"]({ commit }) {
			commit("LOADER", true);
		},
		["hideLoader"]({ commit }) {
			commit("LOADER", false);
		},
		setContractUrl({ commit }, value) {
			commit("setContractUrl", value);
		},
		setClaimUrl({ commit }, value) {
			commit("setClaimUrl", value);
		},
		toggleNewCustomerValid({ commit }, value) {
			commit("setNewCustomerValid", value);
		},
		toggleSourceTypeCompleted({ commit }, value) {
			commit("setSourceTypeCompleted", value);
		},
		toggleReportedByCompleted({ commit }, value) {
			commit("setReportedByCompleted", value);
		},
		toggleCustomerHasCoverage({ commit }, value) {
			commit("setCustomerHasCoverage", value);
		},
		setSourceType({ commit }, value) {
			commit("setSourceType", value);
		},
		setContractNumber({ commit }, value) {
			commit("setContractNumber", value);
		},
		setClaimNumber({ commit }, value) {
			commit("setClaimNumber", value);
		},
		setClaimResults({ commit }, value) {
			commit("setClaimResults", value);
		},
	},
});
