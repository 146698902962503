import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AppShell from "@/components/app-shell.vue";
import PageNotFound from "@/components/page-not-found.vue";
import Unauthorized from "@/components/unauthorized-page.vue";
import AuthCallback from "@/components/auth-callback.vue";
import { authGuard } from "@auth0/auth0-vue";
import ErrorPage from "@/components/error-page.vue";

/* Lazy loading routes */
const VerificationPortal = () => import("@/components/verification-portal.vue");
const VerificationEditContainer = () => import("@/components/verification-edit.vue");
const VerificationAddContainer = () => import("@/components/verification-add.vue");
const VerificationClaims = () => import("@/components/verification-claims.vue");

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		redirect: "/home",
		name: "AppShell",
		component: AppShell,
		beforeEnter: authGuard,
		children: [
			{
				path: "/home",
				name: "Home",
				component: VerificationPortal,
				beforeEnter: authGuard,
			},
			{
				path: "/verification",
				name: "verificationList",
				component: VerificationPortal,
				beforeEnter: authGuard,
			},
			{
				path: "/verification/add",
				name: "verificationAdd",
				component: VerificationAddContainer,
				beforeEnter: authGuard,
			},
			{
				path: "/verification/edit/:id",
				name: "verificationEdit",
				component: VerificationEditContainer,
				beforeEnter: authGuard,
			},
			{
				path: "/claims",
				name: "verificationClaims",
				component: VerificationClaims,
				beforeEnter: authGuard,
			},
			{
				path: "/error",
				name: "ErrorPage",
				component: ErrorPage,
			},
			{
				path: "/testing",
				component: VerificationPortal,
				beforeEnter: () => {
					throw new Error("This is a test router error");
				},
			},
		],
	},
	{
		path: "/auth-callback",
		name: "AuthCallback",
		component: AuthCallback,
	},
	{
		path: "/unauthorized",
		name: "Unauthorized",
		component: Unauthorized,
	},
	{
		path: "/:pathMatch(.*)*",
		name: "PageNotFound",
		component: PageNotFound,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
