import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import Toast from "vue-toastification";
import { configure } from "vee-validate";
import VueDatePicker from "@vuepic/vue-datepicker";
import { auth0 } from "@/services/auth.service";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import LoadingModal from "@/components/loading-modal.vue";
import "@progress/kendo-theme-bootstrap/dist/all.css";

// Add configuration for update validation triggered on input event
configure({
	validateOnInput: true,
});

// Click Analytics configuration
const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
	autoCapture: true,
	dataTags: {
		useDefaultContentNameOrId: true,
	},
};

// Application Insights Configuration
const configObj = {
	connectionString: process.env.VUE_APP_APP_INSIGHTS,
	extensions: [clickPluginInstance],
	extensionConfig: {
		[clickPluginInstance.identifier]: clickPluginConfig,
	},
};
const appInsights = new ApplicationInsights({ config: configObj });
appInsights.loadAppInsights();
appInsights.trackPageView();

// Define a global error handling function
const handleGlobalError = (error: Error | PromiseRejectionEvent | unknown) => {
	let appInsightsException = new Error();
	let pushErrorPage = true;

	// Check for a PromiseRejectionEvent
	if (error instanceof PromiseRejectionEvent) {
		// Check for an "Invalid state" error when the user is not authenticated
		if (error.reason.error_description === "Invalid state") {
			// Redirect the user to the AuthCallback page
			pushErrorPage = false;
			router.push({ name: "AuthCallback" });
		} else if (error.reason.error_description === "Login required") {
			// Redirect the user to the Login page
			pushErrorPage = false;
			auth0.loginWithRedirect();
		} else {
			// Create an Error object from the error reason
			appInsightsException = new Error(error.reason);
		}
	} else {
		if (error instanceof Error) {
			// Create an Error object from the error
			appInsightsException = error;
		} else {
			// Create an Error object from the error stringified
			appInsightsException = new Error(JSON.stringify(error));
		}
	}

	// Send the exception telemetry to AppInsights if the environment is DEV, QA, UAT, or PROD
	appInsights.trackException({ exception: appInsightsException });

	// Redirect the user to the Error page
	if (pushErrorPage) {
		router.push({ name: "ErrorPage" });
	}
};

// Set the global error handler for routing
router.onError((error) => {
	handleGlobalError(error);
});

// Create the Vue application
const app = createApp(App).component("VueDatePicker", VueDatePicker).component("LoadingModal", LoadingModal).use(store).use(router).use(Toast).use(auth0);

// Set the global error handler for the application
app.config.errorHandler = (error) => {
	handleGlobalError(error);
};

// Listen for promise rejections
window.addEventListener("unhandledrejection", (event) => {
	handleGlobalError(event);
});

app.mount("#app");
